import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Card } from '@yleisradio/areena-types';
import { DialogTitle } from 'components/Dialog/Dialog';
import CurrentlyPlaying from 'components/Player/AudioPlayerWrapper/PlayQueue/CurrentlyPlaying';
import EmptyPlayQueue from 'components/Player/AudioPlayerWrapper/PlayQueue/EmptyPlayQueue';
import LoginPromotion from 'components/Player/AudioPlayerWrapper/PlayQueue/LoginPromotion';
import NextInQueueList from 'components/Player/AudioPlayerWrapper/PlayQueue/NextInQueueList';
import NextInSeriesList from 'components/Player/AudioPlayerWrapper/PlayQueue/NextInSeriesList';
import styles from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueue.module.scss';
import { useFeatureFlag } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import { QUEUE_PAGE_SIZE, usePlayerState } from 'contexts/PlayerStateContext';
import { useTunnusContext } from 'contexts/TunnusContext';
import { useCards } from 'hooks/useCards';
import { useTranslation } from 'hooks/useTranslation';
import { useState } from 'react';
import { idFromPointer } from 'utils/pointer';
import { QueueItem } from '../../../../contexts/PlayerStateContext/QueueItem';
import NextInRecommendationsList from './NextInRecommendationsList/NextInRecommendationsList';
import { ToggleQueueCardsPrompt } from './ToggleQueueCardsPrompt/ToggleQueueCardsPrompt';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

type UpdateOperation = 'add' | 'remove';

type Props = {
  ongoingCard: Card | undefined;
};

const removeQueueItem = (
  prevState: QueueItem[],
  selectedQueueItemKeys: string[]
): QueueItem[] => {
  const newState = prevState.filter((item) => {
    return !selectedQueueItemKeys.includes(item.key);
  });
  return newState;
};

const addQueueItem = (
  prevState: QueueItem[],
  selectedQueueItemKeys: string[],
  selectedSeriesCardIndexes: number[],
  seriesCardIds: string[]
): QueueItem[] => {
  const revisionKey = Date.now().toString();

  // Copy in the same order as the items appear in the original queue
  const newQueueItemsFromQueue = prevState
    .filter((item) => selectedQueueItemKeys.includes(item.key))
    .map<QueueItem>((item, index) => ({
      key: `from-queue-${revisionKey}-${index}`,
      programId: item.programId,
    }));

  // Copy in the same order as the items appear in the series list
  const newQueueItemsFromSeries = seriesCardIds
    .filter((_, index) => selectedSeriesCardIndexes.includes(index))
    .map<QueueItem>((id, index) => ({
      key: `from-series-${revisionKey}-${index}`,
      programId: id,
    }));

  return [...prevState, ...newQueueItemsFromQueue, ...newQueueItemsFromSeries];
};

export function PlayQueue({ ongoingCard }: Props) {
  const {
    activePlayer,
    queue,
    recommendationsQueue,
    recommendationsAnalyticsContext,
    showRecommendationsQueue,
    setShowRecommendationsQueue,
    setQueue,
  } = usePlayerState();
  const t = useTranslation();
  const { isAuthenticated } = useTunnusContext();
  const { playQueue2024 } = useFeatureFlag();

  const cardsInQueue = Boolean(ongoingCard || queue.length);
  const {
    cards: seriesCards,
    loading,
    analyticsContext,
  } = useCards({
    source: activePlayer?.playlistSource,
    pageIndex: 0,
    pageSize: QUEUE_PAGE_SIZE,
  });

  const [selectedQueueItemKeys, setSelectedQueueItemKeys] = useState<string[]>(
    []
  );

  const [selectedSeriesCardIndexes, setSelectedSeriesCardIndexes] = useState<
    number[]
  >([]);

  const showQueueControlPrompt =
    selectedQueueItemKeys.length > 0 || selectedSeriesCardIndexes.length > 0;

  const seriesCardIds = seriesCards.map((card) => {
    const cardId = idFromPointer(card.pointer) || '';
    return cardId;
  });

  /* design decision: if series cards chekboxes are clicked, remove button from prompt
   is disabled for all to keep series queue intact */
  const isRemoveQueueCardsButtonDisabled = selectedSeriesCardIndexes.length > 0;

  const updatePlayQueueItems = (operationType: UpdateOperation) => {
    if (operationType == 'remove') {
      setQueue(removeQueueItem(queue, selectedQueueItemKeys));
    } else if (operationType == 'add') {
      setQueue(
        addQueueItem(
          queue,
          selectedQueueItemKeys,
          selectedSeriesCardIndexes,
          seriesCardIds
        )
      );
    } else {
      return;
    }

    setSelectedSeriesCardIndexes([]); //reset series ui cards
    setSelectedQueueItemKeys([]); //reset user queue ui cards
  };

  const handleQueueCheckboxChange = (checked: boolean, key: string) => {
    if (checked) {
      setSelectedQueueItemKeys([...selectedQueueItemKeys, key]);
    } else {
      setSelectedQueueItemKeys(
        selectedQueueItemKeys.filter((currentKey) => currentKey !== key)
      );
    }
  };

  const handleSeriesListCheckboxChange = (checked: boolean, index: number) => {
    if (checked) {
      setSelectedSeriesCardIndexes([...selectedSeriesCardIndexes, index]);
    } else {
      setSelectedSeriesCardIndexes(
        selectedSeriesCardIndexes.filter((i) => i !== index)
      );
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    // dnd id's are queue item keys
    if (active && over && active.id !== over.id) {
      const oldIndex = queue.findIndex((item) => item.key === active.id);
      const newIndex = queue.findIndex((item) => item.key === over.id);

      setQueue(arrayMove(queue, oldIndex, newIndex));
    }
  };

  return (
    <div className={styles.queue}>
      <DialogTitle asChild>
        <h2 className={styles.title}>{t('queue')}</h2>
      </DialogTitle>
      {cardsInQueue && isAuthenticated && (
        <>
          <CurrentlyPlaying />
          {queue.length > 0 && (
            <DndContext
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={handleDragEnd}
              sensors={sensors}
            >
              <SortableContext
                items={queue.map((item) => item.key)}
                strategy={verticalListSortingStrategy}
              >
                <NextInQueueList
                  items={queue}
                  setItemSelection={handleQueueCheckboxChange}
                  selectedItemKeys={selectedQueueItemKeys}
                />
              </SortableContext>
            </DndContext>
          )}
          {seriesCards.length > 0 && (
            <NextInSeriesList
              heading={t('nextInSeries')}
              cards={seriesCards}
              analyticsContext={analyticsContext}
              listKey="play-queue-next-in-series"
              loading={loading}
              handleCheckboxChange={handleSeriesListCheckboxChange}
              selectedCardIndexes={selectedSeriesCardIndexes}
            />
          )}
          {recommendationsQueue.length > 0 && (
            <NextInRecommendationsList
              heading={t('recommendationsInQueue')}
              cards={recommendationsQueue}
              analyticsContext={recommendationsAnalyticsContext}
              listKey="play-queue-recommendations"
              showRecommendationsQueue={showRecommendationsQueue}
              setShowRecommendationsQueue={setShowRecommendationsQueue}
            />
          )}
          {playQueue2024 && showQueueControlPrompt && (
            <ToggleQueueCardsPrompt
              isRemoveDisabled={isRemoveQueueCardsButtonDisabled}
              updateItems={updatePlayQueueItems}
            />
          )}
        </>
      )}
      {!cardsInQueue && isAuthenticated && <EmptyPlayQueue />}
      {!isAuthenticated && <LoginPromotion />}
    </div>
  );
}

import styles from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueue.module.scss';
import { Card, ListStyle } from '@yleisradio/areena-types';
import { PlaceholderCard } from 'components/PlaceholderCard';
import {
  PlayQueueCard,
  QueueCard,
} from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueueCard';
import { getCardKey } from 'utils/card';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';
import { Labels } from 'services/yleAnalyticsSdk';
import { QUEUE_PAGE_SIZE } from 'contexts/PlayerStateContext';
import { useFeatureFlag } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import { idFromPointer } from 'utils/pointer';

type Props = {
  heading: string;
  cards: Card[];
  analyticsContext: Labels | undefined;
  listKey: string;
  loading?: boolean;
  handleCheckboxChange: (checked: boolean, index: number) => void;
  selectedCardIndexes: number[];
};

const listStyle: ListStyle = {
  layout: 'vertical',
  image: '1:1',
  size: 'medium',
};

const NextInSeriesList = ({
  heading,
  cards,
  analyticsContext,
  listKey,
  handleCheckboxChange,
  selectedCardIndexes,
  loading = false,
}: Props) => {
  const { playQueue2024 } = useFeatureFlag();

  return (
    <AnalyticsContextProvider context={analyticsContext}>
      <h3 className={styles.subTitle}>{heading}</h3>
      {cards &&
        cards.map((card, i) => {
          const cardKey = getCardKey(card, listKey);
          const itemId = idFromPointer(card?.pointer) || '';
          const item = { key: '', programId: itemId };
          return (
            <div key={cardKey} className={styles.cardContainer}>
              {playQueue2024 ? (
                <PlayQueueCard
                  isActive={false}
                  item={item}
                  isSimpleCard={playQueue2024}
                  setCheckboxSelected={(value) =>
                    handleCheckboxChange(value, i)
                  }
                  isCheckboxSelected={selectedCardIndexes.includes(i)}
                />
              ) : (
                <QueueCard card={card} isActive={false} cardKey={cardKey} />
              )}
            </div>
          );
        })}

      {loading &&
        !playQueue2024 &&
        Array.from({ length: QUEUE_PAGE_SIZE }, (_, i) => (
          <li key={i} aria-hidden>
            <PlaceholderCard listStyle={listStyle} />
          </li>
        ))}
    </AnalyticsContextProvider>
  );
};

export default NextInSeriesList;

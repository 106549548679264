import { Card } from '@yleisradio/areena-types';
import { sanitizeHtmlId } from 'utils/ui-helpers';
import styles from './SimpleCard.module.scss';
import { useControl } from 'components/Controls/Control/useControl';
import { useTranslation } from 'hooks/useTranslation';
import { Button } from 'components/Button';
import { useSortable } from '@dnd-kit/sortable';
import DragHandleIcon from 'assets/dragHandle.svg';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';

interface Props {
  card: Card;
  cardKey: string;
  isCheckboxSelected: boolean;
  isSortable?: boolean;
  setCheckboxSelected: (value: boolean) => void;
  queueKey?: string;
}

export const SimpleCard: React.FC<Props> = ({
  card,
  cardKey,
  isCheckboxSelected,
  setCheckboxSelected,
  isSortable = false,
  queueKey,
}) => {
  const titleId = sanitizeHtmlId(cardKey + 'title');

  const { controls = [] } = card;
  const playControl = controls.find((c) => c.tag === 'play');

  const { control, action, executingAction } = useControl({
    control: playControl,
  });

  const onClick = !executingAction ? action : undefined;
  const t = useTranslation();

  const {
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ disabled: !isSortable, id: queueKey || cardKey });

  return (
    <div
      data-testid="card"
      aria-labelledby={titleId}
      className={styles.container}
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
      }}
    >
      <label
        aria-label="toggle-queue-card"
        className={styles.queueCheckBoxLabel}
      >
        <input
          type="checkbox"
          className={styles.queueCheckBox}
          checked={isCheckboxSelected}
          onChange={(event) => {
            setCheckboxSelected(event.target.checked);
          }}
        />
      </label>
      <div className={styles.cardTextContentWrapper}>
        <div className={styles.cardTitle}>
          <Button
            pointer={control?.destination}
            text={card.title || ''}
            disabled={!!control?.disabled}
            onClick={onClick}
            variant="text"
            size="sm"
            padding="none"
            aria-label={t('play')}
            textAlign="left"
          />
        </div>
        <span className={styles.cardDescription}>{card.description}</span>
      </div>
      {isSortable ? (
        <button
          className={classNames(
            styles.handle,
            isDragging && styles.handleDragging
          )}
          ref={setActivatorNodeRef}
          {...listeners}
        >
          <DragHandleIcon className={styles.handleIcon} />
          <VisuallyHidden>
            Siirrä ohjelmaa soittojonossa{/* TODO: add translation */}
          </VisuallyHidden>
        </button>
      ) : (
        <div className={styles.handlePlaceholder} />
      )}
    </div>
  );
};
